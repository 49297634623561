.slick-dots li button:before {
	/* color: white; */
}
.slick-dots li.slick-active button:before {
	color: rgb(17, 122, 122);
}

.slick-dots.slick-thumb li {
	width: 24px;
	height: 7px;
	margin: 0 3px;
	border-radius: 999px;
	transition: all 0.5s ease-in-out;
	will-change: width;
	/* background-color: #c4c4c4; */
}

.slick-dots.slick-thumb li.slick-active {
	/* background-color: #ffffff; */
	width: 24px;
}
.slick-dots {
	bottom: 40px;
}
.slick-dots li button:before {
	font-size: 10px;

	width: 30px;
	height: 29px;
	content: '•';
	margin-top: 7px;
}
/* product */
.product {
	position: relative;
	overflow: hidden;
	padding: 20px;
}

.product-category {
	padding: 0 10vw;
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 40px;
	text-transform: capitalize;
}

.product-container {
	padding: 0 10vw;
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;
}

.product-container::-webkit-scrollbar {
	display: none;
}

.product-card {
	flex: 0 0 auto;
	width: 250px;
	height: 450px;
	margin-right: 40px;
}

.product-image {
	position: relative;
	width: 100%;
	height: 350px;
	overflow: hidden;
}

.product-thumb {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.discount-tag {
	position: absolute;
	background: #fff;
	padding: 5px;
	border-radius: 5px;
	color: #ff7d7d;
	right: 10px;
	top: 10px;
	text-transform: capitalize;
}

.card-btn {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	padding: 10px;
	width: 90%;
	text-transform: capitalize;
	border: none;
	outline: none;
	background: #fff;
	border-radius: 5px;
	transition: 0.5s;
	cursor: pointer;
	opacity: 0;
}

.product-card:hover .card-btn {
	opacity: 1;
}

.card-btn:hover {
	background: #ff7d7d;
	color: #fff;
}

.product-info {
	width: 100%;
	height: 100px;
	padding-top: 10px;
}

.product-brand {
	text-transform: uppercase;
}

.product-short-description {
	width: 100%;
	height: 20px;
	line-height: 20px;
	overflow: hidden;
	opacity: 0.5;
	text-transform: capitalize;
	margin: 5px 0;
}

.price {
	font-weight: 900;
	font-size: 20px;
}

.actual-price {
	margin-left: 20px;
	opacity: 0.5;
	text-decoration: line-through;
}

.pre-btn,
.nxt-btn {
	border: none;
	width: 10vw;
	height: 100%;
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
	cursor: pointer;
	z-index: 8;
}

.pre-btn {
	left: 0;
	transform: rotate(180deg);
}

.nxt-btn {
	right: 0;
}

.pre-btn img,
.nxt-btn img {
	opacity: 0.2;
}

.pre-btn:hover img,
.nxt-btn:hover img {
	opacity: 1;
}

.collection-container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
}

.collection {
	position: relative;
}

.collection img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.collection p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #fff;
	font-size: 50px;
	text-transform: capitalize;
}

.collection:nth-child(3) {
	grid-column: span 2;
	margin-bottom: 10px;
}
.fPUkBr {
	display: none;
}
@font-face {
	font-family: 'SourceSansPro';
	src: url('./assets/fonts/SourceSansPro-Regular.otf') format('opentype');
	/* You can also specify font-weight and font-style if needed */
}
.ant-dropdown-menu-title-content {
	font-size: 13px;
	color: '#58595b';
}
.rec.rec-arrow {
	width: 36px;
	height: 36px;
	line-height: 36px;
	min-width: 36px;
}
.kTIDtp {
	width: 36px;
	height: 36px;
	line-height: 36px;
	min-width: 36px;
}
.kTHdYz {
	width: 36px;
	height: 36px;
	line-height: 36px;
	min-width: 36px;
}
.transbox {
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	padding: 20px;
	margin: 30px;
	font-weight: bold;
	position: absolute;
	left: 10%;
	top: 40%;
	width: 100%;
	max-width: 80%;
	z-index: 1;
}
@media (max-width: 600px) {
	.transbox {
		top: 55%;
		left: 4%;
		max-width: 90%;
		margin: 10px;
	}
}
@media (min-width: 600px) and (max-width: 800px) {
	.transbox {
		top: 60%;
		left: 4%;
		max-width: 90%;
		margin: 10px;
	}
}
.myCustomList::-webkit-scrollbar {
	width: 8px;
	background-color: rgb(254, 249, 249);
}

.myCustomList::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: lightgrey;
}
.ant-picker .ant-picker-input > input {
	color: rgba(207, 201, 201, 1);
}
.picker .ant-picker .ant-picker-input > input {
	color: rgb(27, 26, 26);
}
.expiry .ant-picker .ant-picker-input > input {
	color: rgb(27, 26, 26);
}
.birth .ant-picker .ant-picker-input > input {
	color: rgb(27, 26, 26);
}

table,
th,
td {
	border: 1px solid rgba(217, 217, 217, 1);
	border-collapse: collapse;
}
.hoteldatepicker .ant-picker .ant-picker-input > input {
	color: black;
}

.card {
	position: relative;
	/* width: 300px; */
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

.card img {
	width: 100%;
	height: 120px;
	object-fit: fill;
	background-image: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.9) 100%
	);
}

.card .price {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	padding: 8px 12px;
	border-radius: 4px;
	font-size: 14px;
	font-weight: bold;
}
.card .duration {
	position: absolute;
	top: 80px;
	left: 10px;
	/* background-color: rgba(0, 0, 0, 0.6); */
	color: #fff;
	padding: 8px 12px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: bold;
	/* background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%); */
}

.card .details {
	padding: 16px;
}

.card .details .duration {
	font-size: 14px;
	color: #555;
	margin-bottom: 8px;
}

.card .details .title {
	font-size: 14px;
	font-weight: bold;
	color: #333;
}
